import React from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

function ServicesCards() {
  const cardStyle = {
    height: '100%', // Set a fixed height for all cards
    background: '#6EE1F569',
    borderRadius: '16px',
    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
    backdropFilter: 'blur(0px)',
    WebkitBackdropFilter: 'blur(0px)',
    border: '1px solid rgba(110, 225, 245, 1)',
  };

  const cardImgStyle = {
    height: '100px',
    width: '100px',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '4px',
  };

  const cardTextStyle = {
    color: 'white',
  };

  return (
    <Row xs={1} md={2} lg={2} className="g-4 justify-content-center mx-auto">
      <Col key={1}>
        <Card style={cardStyle}>
          <Card.Img variant="top" src="images/support.png" style={cardImgStyle}/>
          <Card.Body>
            <Card.Title style={{ ...cardTextStyle, textDecoration: 'underline',}}>Desktop & Server Support</Card.Title>
            <Card.Text style={cardTextStyle}>
              Your IT lifeline, whenever you need it. From troubleshooting desktop issues to maintaining server performance, our support team is dedicated to keeping your systems running smoothly, minimizing downtime, and maximizing productivity
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col key={2}>
        <Card style={cardStyle}>
          <Card.Img variant="top" src="images/home-auto.png" style={cardImgStyle}/>
          <Card.Body>
            <Card.Title style={{ ...cardTextStyle, textDecoration: 'underline',}}>Home Automation</Card.Title>
            <Card.Text style={cardTextStyle}>
            Transform your living space into a smart home haven. Our home automation solutions integrate lighting, security, climate control, and entertainment systems, offering seamless control and convenience at your fingertips. Enjoy enhanced comfort, energy efficiency, and peace of mind with the power of automation.
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col key={3}>
        <Card style={cardStyle}>
          <Card.Img variant="top" src="images/builds.png" style={cardImgStyle}/>
          <Card.Body>
            <Card.Title style={{ ...cardTextStyle, textDecoration: 'underline',}}>Custom Computer Builds</Card.Title>
            <Card.Text style={cardTextStyle}>
              Tailored computing solutions designed for your needs. Whether for gaming, business, or specialized tasks, we create high-performance, reliable systems to fuel your success.
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col key={4}>
        <Card style={cardStyle}>
          <Card.Img variant="top" src="images/networking.png" style={cardImgStyle}/>
          <Card.Body>
            <Card.Title style={{ ...cardTextStyle, textDecoration: 'underline',}}>Networking</Card.Title>
            <Card.Text style={cardTextStyle}>
              Building the backbone of seamless connectivity. Our networking solutions optimize your digital infrastructure, ensuring fast and secure data flow for enhanced collaboration and efficiency within your organization.
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col key={5}>
        <Card style={cardStyle}>
          <Card.Img variant="top" src="images/website.png" style={cardImgStyle}/>
          <Card.Body>
            <Card.Title style={{ ...cardTextStyle, textDecoration: 'underline',}}>Website Development</Card.Title>
            <Card.Text style={cardTextStyle}>
              Transforming ideas into immersive online experiences. Our developers craft visually stunning and functionally robust websites tailored to elevate your brand and engage your audience.
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default ServicesCards;
