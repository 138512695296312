import React from 'react';
import './Home.css';
import '../global.css';

const Home = () => (
    <section className="home">
        <div>
            <h2><u><b>Welcome to 3CA-IT - Where Innovation Thrives!</b></u></h2>
            <p>At 3CA-IT, we are not just about technology; we are about transformation. Step into a world where possibilities unfold, and your digital aspirations take flight. Our commitment is encapsulated in our punchline: <b>Empowering Your Digital Ecosystem.</b></p>
            <p>In a rapidly evolving digital landscape, we understand the importance of not just keeping up, but staying ahead. As your trusted partner, we are dedicated to providing cutting-edge solutions that go beyond the ordinary, elevating your digital experience to new heights</p>
            <h2><u><b>Why Choose 3CA-IT?</b></u></h2>
            <div className="lu-container">
                <ul>
                    <li><b>Innovation at the Core:</b> We thrive on innovation, constantly pushing boundaries to bring you the latest in technology.</li>
                    <li><b>Tailored Solutions:</b> Your goals are unique, and so are our solutions. We craft strategies that align seamlessly with your vision.</li>
                    <li><b>Exceptional Support:</b> Our team is here for you every step of the way. From ideation to implementation, we've got you covered.</li>
                </ul>
            </div>
            <h3>Embark on the Future with 3CA-IT. Empower Your Digital Tomorrow!</h3>
        </div>
    </section>
);

export default Home;
