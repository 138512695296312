import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-scroll';

const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  text-wrap: nowrap;

  @media (max-width: 1050px) {
    flex-flow: column nowrap;
    background-color: #0D2538;
    position: fixed;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    top: 0;
    right: 0;
    height: 100vh;
    width: 300px;
    padding-top: 3.5rem;
    transition: transform 0.3s ease-in-out;

    li {
      color: #fff;
    }
  }
`;

const RightNav = ({ open, setOpen }) => {
  const handleClick = () => {
    setOpen(false); // Close navbar on link click
  };

  return (
    <Ul open={open}>
      <Link to="home" spy={true} smooth={true} offset={-130} duration={500} onClick={handleClick}>
        <li>Home</li>
      </Link>
      <Link to="services" spy={true} smooth={true} offset={-130} duration={500} onClick={handleClick}>
        <li>Services</li>
      </Link>
      <Link to="contact" spy={true} smooth={true} offset={-130} duration={500} onClick={handleClick}>
        <li>Contact Us</li>
      </Link>
    </Ul>
  );
};

export default RightNav;
