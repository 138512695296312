// src/components/Header.js
import React from 'react';
import './Header.css';
import '../global.css';
import Navbar from './Nav/Navbar';

const Header = () => {

  return (
    <div>
      <div className={`header`}>
        <div className="header-left">
          <a href="/" className="logo">
            <img className='h-logo' src={"/images/3ca-it-transparent-2.png"} alt='' />
          </a>
        </div>
        <div>
          <h1 className='h-title'>Empowering Your Digital Ecosystem</h1>
        </div>
        <div className="header-right">
          <Navbar />
        </div>
      </div>
    </div>
  );
};

export default Header;
