import React from 'react';
import ServicesCards from './ServicesCards';
import '../global.css';

const Services = () => (
    <section className="services">
        <div>
            <h2><u><b>Our Services:</b></u></h2>

<p>We bring technology to life, ensuring your business thrives in the digital realm. Let's build, host, and support your success together!</p>
            <ServicesCards />
        </div>
    </section>
);
export default Services;
