import React, { useEffect, useState } from 'react';
import './App.css';
import Header from './components/Header';
import Home from './components/Home';
import Services from './components/Services';
import Contact from './components/Contact';
import 'bootstrap/dist/css/bootstrap.min.css';
import './global.css';
import { Helmet } from "react-helmet-async";

const App = () => {
  const [canonicalUrl, setCanonicalUrl] = useState('');

  useEffect(() => {
    // Set the canonical URL to the current window location
    setCanonicalUrl(window.location.href);
  }, []);

  return (
    <>
      <Helmet>
        <meta 
          name="description" 
          content="3CA-IT Empowers your digital ecosystem with tailored IT solutions. Everything from desktop support to networking and home automation, right at your fingertips."
        />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <div className="app">
        <Header />
        <div className="main-content">
          <div className="center-section">
            <Home />
            <hr className='hr' />
            <Services />
            <hr className='hr' />
            <Contact />
          </div>
        </div>
      </div>
    </>
  );
};

export default App;
