// src/components/Contact.js
import React from 'react';

const Contact = () => (
  <section className="contact">
    <h2>Contact Us</h2>
    <p>Email: info@3ca-it.co.za</p>
    <p>Phone: +27 79 763 3855</p>
  </section>
);

export default Contact;